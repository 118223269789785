import PropTypes from 'prop-types';
import React from 'react';
import { NotFound } from 'ui/screens';

const NotFoundPage = ({ location }) => {
  return <NotFound location={location}></NotFound>;
};

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
